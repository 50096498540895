import React, { useState } from "react"
import {
  Wrapper,

  ButtonWrapper,

  CSVWrapper,
  Spacer,
  CSVButtonWrapper,
  TableWrapper,

  /* HEADER */
  TitleInfos,
  TitleInfo,

  /* SHOPS */
  ShopsInfos,

  /* SHOP */
  ShopInfos,
  ShopInfo,
  LinkWrapper,

  /**/
  LineWrapper,
  LineWrapper2,
} from "./Commerces.module.css"
import { OrangeTitle } from "./../../../css/Titre.module.css"
import Popup from "./../../../components/Popup"
import Button from "./../../../components/Button"
import Edit from "./../../../res/icons/edit.png"
import Trash from "./../../../res/icons/trash.png"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import firebase from "gatsby-plugin-firebase"
import { CSVLink } from "react-csv"
const isBrowser = typeof window !== "undefined"

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const HeaderShopsTable = () => {
  return (
    <div className={TitleInfos}>
      <p className={TitleInfo}>Nom</p>
      <p className={TitleInfo}>Description</p>
      <p className={TitleInfo}>Adresse</p>
      <p className={TitleInfo}>Catégorie</p>
      <p style={{ width: "200px" }}>Statistiques</p>
      <p style={{ width: "100px" }}>Modifier</p>
      <p style={{ width: "100px" }}>Supprimer</p>
    </div>
  )
}

const ShopsInfosInTable = ({ shop, trashIsClicked }) => {
  const editIsClicked = () => {
    if (isBrowser) {
      window.location.href = "/admin/commerces/modifier?id=" + shop["id"]
    }
  }

  return (
    <div className={ShopInfos}>
      <p className={ShopInfo}>{shop.name}</p>
      <div className={ShopInfo}>
        <p style={{ width: "400px" }}>{shop.description}</p>
      </div>
      <div className={ShopInfo}>
        <p style={{ width: "400px" }}>{shop.address}</p>
      </div>
      <p className={ShopInfo}>{shop.category}</p>
      <div style={{ width: "200px" }}>
        <a
          className={LinkWrapper}
          href={"/admin/commerces/statistiques?id=" + shop.id}
        >
          Voir
        </a>
      </div>
      <div style={{ width: "100px" }}>
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={editIsClicked}
        >
          <img alt="edit" src={Edit} width="15px" height="15px" />
        </button>
      </div>
      <div style={{ width: "100px" }}>
        <button
          style={{ border: "none", backgroundColor: "transparent" }}
          onClick={trashIsClicked}
        >
          <img alt="trash" src={Trash} width="15px" height="15px" />
        </button>
      </div>
    </div>
  )
}

const Commerces = ({ shops }) => {
  const [successSnackbarIsOpened, setSuccessSnackbarIsOpened] = useState(false)
  const [trashPopupIsOpened, setTrashPopupIsOpened] = useState(false)
  const [shopTrashSelected, setShopTrashSelected] = useState(0)
  const date = new Date()

  const headersCSVFile = [
    { label: "Index", key: "index" },
    { label: "Nom", key: "name" },
    { label: "Description", key: "description" },
    { label: "Catégorie", key: "category" },
    { label: "Adresse", key: "address" },
  ]

  const toggleTrashPopup = idx => {
    setShopTrashSelected(idx)
    setTrashPopupIsOpened(!trashPopupIsOpened)
  }

  const handleDeleteShop = () => {
    toggleTrashPopup()
    firebase
      .firestore()
      .collection("shops")
      .doc(shops[shopTrashSelected].id)
      .delete()
      .then(() => {
        setSuccessSnackbarIsOpened(true)
      })
      .catch(error => {
        //console.log("error when deleting: ", error)
      })
  }

  const handleSuccessClose = () => {
    setSuccessSnackbarIsOpened(false)
  }

  const getData = () => {
    const data = []

    shops.map((shop, i = 0) =>
      data.push({
        index: ++i,
        name: shop.name,
        description: shop.description,
        category: shop.category,
        address: shop.address,
      })
    )
    return data
  }

  const getFilename = () => {
    return (
      "wynit_partenaires_" +
      date.getDate() +
      "_" +
      (date.getMonth() + 1 < 10 ? "0" : "") +
      (date.getMonth() + 1) +
      "_" +
      date.getFullYear() +
      ".csv"
    )
  }

  return (
    <div className={Wrapper}>
      <h1 className={OrangeTitle}>Commerces</h1>
      <div className={ButtonWrapper}>
        <Button alt="" onClick={() => window.location.href = "/admin/commerces/ajouter"}>
          Ajouter
        </Button>
      </div>
      <div className={CSVWrapper}>
        <div className={Spacer}></div>
        <div className={CSVButtonWrapper}>
          <CSVLink
            data={getData()}
            headers={headersCSVFile}
            filename={getFilename()}
            target="_blank"
          >
            <Button alt="">Exporter en CSV</Button>
          </CSVLink>
        </div>
      </div>
      <div className={TableWrapper}>
        <HeaderShopsTable />
        <div className={LineWrapper}></div>
        <div className={ShopsInfos}>
          {shops.map((shop, idx = 0) => (
            <>
              <ShopsInfosInTable
                shop={shop}
                trashIsClicked={() => toggleTrashPopup(idx++)}
              />
              <div className={LineWrapper2}></div>
            </>
          ))}
        </div>
      </div>
      {trashPopupIsOpened && (
        <Popup
          title={"Attention !"}
          onClose={() => toggleTrashPopup()}
          question={"Êtes-vous sûr de vouloir supprimer ce commerce ?"}
          onCancel={() => toggleTrashPopup()}
          onOk={() => handleDeleteShop()}
        />
      )}
      <Snackbar
        open={successSnackbarIsOpened}
        autoHideDuration={5000}
        onClose={handleSuccessClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSuccessClose} severity="success">
          Bravo ! Tu as bien supprimé ce commerce !
        </Alert>
      </Snackbar>
    </div>
  )
}

export default Commerces

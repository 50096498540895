import React, { useState, useEffect } from "react";
import Layout from "./../../../components/Layout";
import Commerces from "./../../../nav/Admin/Commerces";
import { Helmet } from "react-helmet";
import firebase from "gatsby-plugin-firebase";
import { getUser } from "./../../../auth";
import Error403 from "../../../nav/Error403";
import { TailSpin } from "react-loader-spinner";
import { getShopFormatted } from "./../../../utils/formating";

const HandleUserStates = ({ user, shops }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (user && user.subscription.type === "admin") {
    return <Commerces shops={shops} />;
  } else {
    return <Error403 />;
  }
};

const CommercesPage = () => {
  const [user, setUser] = useState(undefined);
  const [shops, setShops] = useState([]);

  useEffect(() => {
    getUser(setUser);
    firebase
      .firestore()
      .collection("shops")
      .get()
      .then((docs) => {
        let idx = 0;
        let newShops = [];

        while (idx < docs.docs.length) {
          newShops.push(getShopFormatted(docs.docs[idx]));
          idx++;
        }
        setShops(newShops);
      })
      .catch((error) => {
        //console.log("Error getting document:", error)
      });
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Commerces</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici les informations concernant les commerces partenaires de WYNIT."
        />
        <meta
          name="keywords"
          content="réductions,jeunes,admin,commerces,liste"
        />
        <link rel="canonical" href="https://wynit.com/admin/commerces" />
      </Helmet>
      <HandleUserStates user={user} shops={shops} />
    </Layout>
  );
};

export default CommercesPage;

// extracted by mini-css-extract-plugin
export var ButtonWrapper = "Commerces-module--ButtonWrapper--TVERV";
export var CSVButtonWrapper = "Commerces-module--CSVButtonWrapper--AgUS5";
export var CSVWrapper = "Commerces-module--CSVWrapper--qI3L2";
export var LineWrapper = "Commerces-module--LineWrapper--Tg3JQ";
export var LineWrapper2 = "Commerces-module--LineWrapper2--HiUx8";
export var LinkWrapper = "Commerces-module--LinkWrapper--8cELM";
export var ShopInfo = "Commerces-module--ShopInfo--CLa84";
export var ShopInfos = "Commerces-module--ShopInfos--W2hYj";
export var ShopsInfos = "Commerces-module--ShopsInfos--dcE4g";
export var Spacer = "Commerces-module--Spacer --GaNm2";
export var TableWrapper = "Commerces-module--TableWrapper--OTGjB";
export var TitleInfo = "Commerces-module--TitleInfo--G3cBh";
export var TitleInfos = "Commerces-module--TitleInfos--HwlWY";
export var Wrapper = "Commerces-module--Wrapper--ZIfSM";